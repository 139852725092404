function addTextSizer(){
    $('#Col2 #Content').before('<div id="TextSizer" class="ClearFix"><h2>Text Size</h2><ul>' +
				'<li><a class="NormalFont" href="javascript:;" title="Small Font Size"></a></li>' +
				'<li><a class="LargeFont" href="javascript:;" title="Medium Font Size"></a></li>' +
				'<li><a class="X-LargeFont" href="javascript:;" title="Large Font Size"></a></li>' +
			'</ul></div>');
	$('a.NormalFont').click(function() {$('#Content').removeClass('textLarge').removeClass('textXLarge');$.cookie('text_sizer', null, { path: '/' });});
	$('a.LargeFont').click(function() {$('#Content').addClass('textLarge').removeClass('textXLarge');$.cookie('text_sizer', 'large', { path: '/' });});
	$('a.X-LargeFont').click(function() {$('#Content').removeClass('textLarge').addClass('textXLarge');$.cookie('text_sizer', 'xlarge', { path: '/' });});
	$('#TextSizer a').click(function() {$('#TextSizer a').removeClass('active');$(this).addClass('active');});
	var text_sizer_cookie = $.cookie('text_sizer');
	if (text_sizer_cookie==null){$('a.NormalFont').addClass('active')};
	if (text_sizer_cookie=='large'){ $('#Content').addClass('textLarge');$("a.LargeFont").addClass('active') };
	if (text_sizer_cookie=='xlarge'){ $('#Content').addClass('textXLarge');$("a.X-LargeFont").addClass('active') };
}

function addEmailLink(){
	var clientDomainName = '[clientDomainName]',
		clientSiteName = '[clientSiteName]',
		currentPageUrl = document.location,
		currentPageTitle = $.trim($('title').text()),
		emailSubject = clientDomainName + ': ' + currentPageTitle,
		emailHref = 'mailto:?subject=' + escape(emailSubject) + '&body=I thought you would be interested in a page on the ' + escape(clientSiteName) + ' Web site: ' + escape(currentPageTitle) + ' - ' + escape(currentPageUrl);
	$('#Col2 #Content').before('<p class="EmailLink"><a href="' + emailHref + '">Email this page</a></p><p class="PrintLink"><a href="javascript:;" onclick="print();">Print Page</a></p>');
}

function addMaskedEntries() {
	$(".PhoneMasked input, input.PhoneMasked").mask("999-999-9999", { placeholder: " " });
	$(".PhoneMaskedExt input, input.PhoneMaskedExt").mask("999-999-9999? x999", { placeholder: " " });
	$(".DateMasked input, input.DateMasked").mask("99/99/9999", { placeholder: " " });
	$(".SSNMasked input, input.SSNMasked").mask("999-99-9999", { placeholder: " " });
	
	$('.PhoneMasked small:contains("Example: 123-123-1234")').hide();
	$('.PhoneMaskedExt small:contains("Example: 123-123-1234")').hide();
	$('.SSNMasked small:contains("Example: 000-00-0000")').hide();
}


$(document).ready(function(){
	
// ----------- TEMPLATE EXTRAS ------------------//
	addMaskedEntries(); // Needed for Event & Form inputs that are masked. -dab

	console.log("TESTING")

// ----------- HACK LIST START ------------------//

// ----------- HACK LIST END ------------------//
});
